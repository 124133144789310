<template>
    <div class="imageComponent">
        <div class="imageContainer" v-if="!error">
            <div class="showPopup hide" id="lightbox" @click="hide" ref="imagePopup"></div>
            <div class="creativeWrapper">
                <div v-if="loading" class="loaderImg">
                    <div class="loader"></div>
                </div>

                <div
                    v-else
                    ref="imageField"
                    class="imageField"
                   
                    @click="show"
                ></div>
            </div>
        </div>
        <div class="reloadImage" v-else>
            <div class="reloadTile">
                Ошибка загрузки
            </div>
            <div class="reloadTile">
                <button @click="reloadImage" class="btn btn2 ">
                   <v-icon> mdi-cached </v-icon>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: ["item"],
    data: () => ({
        limitHeight: false,
        img_field: null,
        error: false,
        isdownloaded: false,
        visible: false,
        loading: false,
        media: "",
        appolonMedia: {},
        stylePopupimg: `
    position: absolute;
    top: 50%;
    max-width:90%;
    max-height:90%;
    left: 50%;
    cursor:pointer;
    transform: translate(-50%, -50%);
    z-index: 9999;`
    }),
    watch: {
        item() {
            this.getImage();
        }
    },
    methods: {
        ...mapActions(["GET_IMAGE"]),
        show() {
            if (this.item.mimeType.split("/")[0] === "image") {
                this.visible = true;
                this.$refs.imagePopup.classList = " showPopup ";
            }
        },
        hide() {
            this.visible = false;
            this.$refs.imagePopup.classList = "showPopup hide";
        },
        onKeydown(e) {
            if (this.visible) {
                switch (e.key) {
                    case " ":
                        e.preventDefault();
                        break;
                    case "Escape":
                        this.hide();
                        break;
                }
            }
        },

        createData() {
            let contentItempopup = document.createElement("img");
            contentItempopup.setAttribute("style", this.stylePopupimg);
            let typeItem = this.item.mimeType.split("/")[0];
            let contentItem = "";
            if (typeItem === "image") {
                contentItem = document.createElement("img");
                contentItem.setAttribute("width", "100%");
                this.img_field.appendChild(contentItem);
            } else {
                const videoElem = document.createElement("video");
                videoElem.setAttribute("controls", "controls");
                videoElem.setAttribute("controlsList", "nodownload");
                videoElem.setAttribute("disablePictureInPicture", "true");
                videoElem.setAttribute("width", "100%");
                this.img_field.appendChild(videoElem);
                const source = document.createElement("source");
                // source.setAttribute("type", `video/mp4; codecs="mp4v.20.8, samr"`);
                source.setAttribute("type", "video/mp4");
                source.setAttribute('webkit-playsinline', 'webkit-playsinline');
                this.img_field = videoElem;
                contentItem = source;
            }
            this.appolonMedia = { typeItem, contentItempopup, contentItem };
            if (this.item.imageBlob) {
                this.renderCreative(
                    this.item.imageBlob,
                    this.appolonMedia.typeItem,
                    this.appolonMedia.contentItempopup,
                    this.appolonMedia.contentItem,
                    this.img_field
                );
            } else {
                this.getImage();
            }
        },
        getImage() {
            this.loading = true;
            this.GET_IMAGE(this.item).then(el => {
                if (el.status == false) {
                    this.error = true;
                    this.isdownloaded = false;
                } else if (el.status == undefined) {
                    this.renderCreative(
                        el,
                        this.appolonMedia.typeItem,
                        this.appolonMedia.contentItempopup,
                        this.appolonMedia.contentItem,
                        this.img_field
                    );
                }
            });
        },
        renderCreative(el, typeItem, contentItempopup, contentItem, refImg) {
            let imagePopupref = this.$refs.imagePopup;
            this.isdownloaded = true;
            this.loading = false;
            const reader = new FileReader();
            reader.readAsDataURL(el);
            reader.onload = function() {
                if (typeItem === "image") {
                    contentItempopup.setAttribute("src", reader.result);
                    imagePopupref.appendChild(contentItempopup);
                }
                contentItem.setAttribute("src", reader.result);
                refImg.appendChild(contentItem);
            };
        },
        needLoadImg() {
            this.createData();
        },
        reloadImage() {
            console.log("reload image");
            this.error = false;
            this.getImage();
        }
    },
    mounted() {
        window.addEventListener("keydown", this.onKeydown);
        this.img_field = this.$refs.imageField;
        if (localStorage.getItem("limitHeight") && localStorage.getItem("limitHeight") == true) {
            this.limitHeight = true;
            console.log("123");
        }
        this.needLoadImg();
    },

    destroyed() {
        window.removeEventListener("keydown", this.onKeydown);
    }
};
</script>

<style lang="scss">
@import "../assets/css/imageitem.scss";
</style>
