<template>
  <div class="changeStatusWrap">
    <div v-if="loading" class="loaderImg">
      <div class="loader"></div>
    </div>
    <div class="chiplistWrapper" v-else>
      <DialogDecline :openDialog="confirmDialog" />
      <h3 class="statusTitle">Поменять статус на:</h3>
      <v-chip-group
        v-model="selection"
        class="chipList"
        active-class=" accent-4"
        column
      >
        <v-chip v-for="(i, index) in stateApp" label :key="i" :class="`${i}`">{{
          stateRus[index]
        }}</v-chip>
      </v-chip-group>
      <button class="btn chipListBtn" @click="saveItem">Подтвердить</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import DialogDecline from "./DialogDecline";
export default {
  props: ["item"],
  components:{
    DialogDecline
  },
  data: () => ({
    selection: null,
    movingTo: null,
    loading: false,
    confirmDialog: false,
    stateRus: ["В ожидании", "Одобрено", "Отклонено"],
  }),
  computed: {
    stateApp(){
      return this.$store.getters.GET_STATE
    }
  },
  created: function () {
    this.selection = this.stateApp.indexOf(this.item.status);
  },
  mounted() {
    // this.getCommentList(this.item.id);
  },
  methods: {
    ...mapActions(["CHANGE_FILE_STATUS"]),
    ...mapMutations(["STATUS_CREATIVE"]),
    saveItem() {
      if (
        this.selection === undefined ||
        this.selection == this.stateApp.indexOf(this.item.status)
      ) {
        this.selection = this.stateApp.indexOf(this.item.status);
      } else {
        this.loading = true;
        if (this.selection == 2) {
          if ( this.item.commentsLength == 0) {
            this.loading = false;
            this.confirmDialog = !this.confirmDialog;
          } else {
            this.changeStatus(this.stateApp[this.selection]);
          }
        } else {
          this.movingTo = this.stateApp[this.selection];
          this.changeStatus(this.stateApp[this.selection]);
        }
      }
    },
    changeStatus(selection) {
      this.CHANGE_FILE_STATUS({
        id: this.item.id,
        to: selection,
      }).then((el) => {
        if (el === true) {
          const data = {
            id: this.item.id,
            movefrom: this.item.status,
            moveto: this.stateApp[this.selection],
          };
          this.STATUS_CREATIVE(data);
        }
        this.loading = false;
        console.log("changed", el);
      });
      /*  const data = {
            id: this.item.id,
            movefrom: this.item.status,
            moveto: this.stateApp[this.selection],
          };
          this.STATUS_CREATIVE(data); */
    },
  },
};
</script>

<style lang="scss" >
@import "../assets/css/changeStatus.scss";
</style>