<template>
    <div :id="item.id" class="comment_description">
        <div class="action-list" v-if="showBtnlist">
            <div
                v-if="item.description"
                class="action-btn"
                :class="{ activeTab: openDescription }"
                @click="showDescription"
            ><v-icon> mdi-subtitles </v-icon>
                <span class="text">Описание</span>
            </div>
            <div class="action-btn commentBtnWrapper" :class="{ activeTab: commentsActive }" @click="showCom">
                <v-icon> mdi-message-text </v-icon>
                <span class="text" v-if="!comment.length">Комментировать </span>
                <span class="text" v-else>Комменты ({{ comment.length }}) </span>

                <div class="newMessIcon" v-if="newMess"></div>
            </div>
        </div>
        <transition name="slide-fade">
            <div v-if="openDescription">
                <div class="comAndDecTitle"><span>Описание:</span></div>
                <p class="description">{{ item.description || "Отсутствует" }}</p>
            </div>
        </transition>
        <transition name="slide-fade">
            <div class="commentField" v-if="commentsActive">
                <div class="comAndDecTitle"><span>Комментарии:</span></div>
                <div class="commentsBox">
                    <div v-if="comment.length" class="commentsList">
                        <div class="loaderImg" v-if="loading">
                            <div class="loader"></div>
                        </div>
                        <div class="commentsListBox" v-else>
                            <div class="commentText" v-for="(i, index) in comment" :key="index">
                                <div class="commentBox">
                                    <div class="commentAndImage">
                                        <div v-if="i.content.includes('%file%')" class="commentBlockComment">
                                            <span class="">
                                                <span
                                                    >Файл: <strong>{{ imageComment(i.content) }}</strong></span
                                                >
                                            </span>
                                            <div class="btnLists">
                                                <v-btn icon class="hidIcon" @click="delCom(i)">
                                                    <v-icon> mdi-delete </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div v-else class="commentBlockComment">
                                            <span class="commentContent">
                                                <span>{{ i.content }}</span>
                                                <div class="addloader" v-if="i.id == 0"></div>
                                            </span>
                                            <div class="btnLists" v-if="i.id != 0">
                                                <v-btn icon class="hidIcon" @click="delCom(i)">
                                                    <v-icon> mdi-delete </v-icon>
                                                </v-btn>
                                                <v-btn icon class="hidIcon" @click="editCom(i)">
                                                    <v-icon> mdi-pencil </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="replies" v-for="(ind, index) in i.replies" :key="index">
                                        <div class="information">
                                            <p class="author">
                                                от <span>{{ ind.author }}</span
                                                >:
                                            </p>
                                            <p class="date">{{ newDate(ind.time) }}</p>
                                        </div>

                                        <p class="reply">{{ ind.content }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <span
                            >Ниже можете оставить комментарий , а также прикрепить фото который нужно добавить на
                            креатив или скрин с замечаними</span
                        >
                    </div>
                    <div class="d-flex align-center">
                        <v-file-input small-chips v-model="fileImage" label="Добавьте фото"></v-file-input>
                        <v-btn icon @click="checkFileField">
                            <v-icon> mdi-send </v-icon> </v-btn>
                    </div>
                    <!-- autofocus -->
                    <v-textarea
                        class="textareaField"
                        outlined
                        no-resize
                        clearable
                        :autofocus="displayName"
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        label="Добавьте комментарий"
                        v-model="textComment"
                        placeholder="Например: «Поменять логотип», «Сменить разрешение изображения» и т.д."
                    >
                    </v-textarea>
                    <button @click="reloadComments" class="reloadComments">
                        <v-icon> mdi-cached </v-icon>
                    </button>
                    <div class="addBtnWrapper" v-if="textComment">
                        <button @click="checkCommentField" class="btn btnAdd">
                            Добавить
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <v-dialog v-model="dialogEdit" class="confirmDialog" max-width="434px">
            <div class="confirmWraper">
                <div class="confirmTitle">Исправление комментария</div>
                <v-row cols="12" sm="6" md="4">
                    <v-text-field v-model="editTextField" label="Исправить на:"></v-text-field>
                </v-row>
                <div class="confirmBtnList">
                    <button class="btn" @click="editItemConfirm">Сохранить</button>
                    <button class="btn btn2" @click="close('edit')">Отмена</button>
                </div>
            </div>
        </v-dialog>
        <v-dialog class="confirmDialog" max-width="434px" v-model="dialogDelete">
            <div class="confirmWraper">
                <div class="confirmTitle">Вы точно уверены что хотите удалить?</div>
                <div class="confirmBtnList">
                    <button class="btn" @click="deleteItemConfirm">Удалить</button>
                    <button class="btn btn2" @click="close('del')">Отмена</button>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
export default {
    props: ["item", "activateAppItemPage"],
    data: () => ({
        loading: false,
        openDescription: false,
        commentsActive: false,
        dialogEdit: false,
        dialogDelete: false,
        isDeleting: "",
        isEditing: "",
        editTextField: "",
        textComment: "",
        comment: "",
        show: true,
        fileImage: [],
        showBtnlist: true
    }),
    watch: {
        comment(oldval) {
            if (oldval.length == 0) {
                this.set_comment(oldval);
            }
        },
        item() {
            this.loadCom();
        }
    },
    computed: {
        newMess() {
            if (this.comment.length) {
                const arr = this.comment[this.comment.length - 1];
                if (arr.replies) {
                    if (arr.replies.length != 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else return false;
            }
        },
        displayName() {
            if (["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)) {
                return false;
            } else return true;
        }
    },
    mounted() {
        if (this.activateAppItemPage == true) {
            this.showCom();
            this.showDescription();
            this.showBtnlist = false;
        } else {
            if (!this.item.commentsLength) {
                this.loadCom();
            } else {
                this.comment = this.item.commentsList;
            }
        }
    },
    methods: {
        ...mapActions(["GET_COMMENTS_FROM_API", "ADD_COMMENT", "DELETE_COMMENT", "EDIT_COMMENT", "ADD_FILE_COMMENT"]),
        ...mapMutations(["SET_COMMENTS"]),
        imageComment(str) {
            return str.slice(6);
        },
        newDate(str) {
            let date = new Date(Date.parse(str));
            return (
                date.getHours() +
                ":" +
                date.getMinutes() +
                " " +
                date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear()
            );
        },
        showDescription() {
            this.openDescription = !this.openDescription;
        },
        showCom() {
            this.commentsActive = !this.commentsActive;
            const id = this.item.id;
            if (this.commentsActive) {
                window.scrollBy(0, 260);
                //   const scrollpointer = this.$refs.id
                //   scrollpointer.setAttribute("id", "scrollplace")
                //    this.$vuetify.goTo(`#${id}`)
            }
        },
        loadCom() {
            this.loading = true;
            this.GET_COMMENTS_FROM_API(this.item.id).then(el => {
                this.comment = el.reverse();
                this.set_comment(el);
                this.loading = false;
            });
        },
        set_comment(commentlist) {
            this.SET_COMMENTS({
                id: this.item.id,
                status: this.item.status,
                data: commentlist
            });
        },
        reloadComments() {
            this.comment = "";
            this.loadCom();
        },
        checkFileField() {
            if (this.fileImage.length !== 0) {
                this.sendFile();
                console.log("sending");
            } else {
                console.log("empty");
            }
        },
        checkCommentField() {
            if (this.textComment) {
                this.addCom(this.textComment);
                this.textComment = "";
            }
        },
        sendFile() {
            const fileName = this.fileImage.name;
            if (this.fileImage) {
                this.loading = true;
                let formData = new FormData();
                formData.append("file", this.fileImage);
                this.ADD_FILE_COMMENT(formData).then(() => {
                    this.fileImage = [];
                    //this.loading = false;
                    /* this.addCom("%file%" + el.data); */
                    this.addCom("%file%" + fileName);
                });
            }
        },
        addCom(textComment) {
            const commentId = 0;
            this.comment.push({
                id: commentId,
                content: textComment
            });
            this.ADD_COMMENT({
                file_id: this.item.id,
                comment_id: "string",
                content: textComment
            }).then(el => {
                if (el.status) {
                    const id_array = this.comment.filter(el => {
                        return el.id != commentId;
                    });
                    this.comment = id_array;
                    this.comment.push({
                        id: el.data,
                        content: textComment
                    });
                    this.loading = false;
                    this.set_comment(this.comment);
                }
            });
        },
        delCom(itemCom) {
            this.dialogDelete = !this.dialogDelete;
            this.isDeleting = itemCom;
        },
        deleteItemConfirm() {
            const del_data = {
                file_id: this.item.id,
                id: this.isDeleting.id
            };
            this.comment.map(el => {
                if (el.id == this.isDeleting.id) {
                    el.id = 0;
                }
            });
            this.DELETE_COMMENT(del_data).then(() => {
                const del_arr = this.comment.filter(el => {
                    return el.id != this.isDeleting.id;
                });
                this.comment = del_arr;
            });
            this.dialogDelete = !this.dialogDelete;
        },
        editCom(itemCom) {
            this.dialogEdit = !this.dialogEdit;
            this.isEditing = itemCom;
            this.editTextField = itemCom.content;
        },
        editItemConfirm() {
            this.loading = true;
            this.EDIT_COMMENT({
                file_id: this.item.id,
                id: this.isEditing.id,
                content: this.editTextField
            }).then(() => {
                this.loadCom();
            });
            this.dialogEdit = !this.dialogEdit;
        },
        close(dialog) {
            dialog == "edit" ? (this.dialogEdit = false) : (this.dialogDelete = false);
        }
    }
};
</script>
<style lang="scss">
@import "../assets/css/comndesc.scss";
</style>
