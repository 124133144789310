<template>
  <div class="filterWrapper">
    <v-menu
      nudge-left="370%"
      bottom
      rounded="lg"
      :close-on-content-click="false"
      v-model="menuTag"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on: menusearch, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <div class="menuCleanFilter">
              <v-btn
                color="primary"
                dark
                class="ma-1"
                small
                fab
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menusearch }"
              >
                <v-icon>mdi-pound</v-icon>
              </v-btn>
              <v-btn
                v-if="tagSearchModel.length"
                class="btnCleanFilter"
                icon
                @click="cleanTagSearch"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </template>
          <span>Поиск по тэгам</span>
        </v-tooltip>
      </template>
      <v-list rounded class="px-6">
        <div class="d-flex ma-2 align-center">
          <!-- <v-subheader>Фильтр по #тэгам</v-subheader> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="checktags = !checktags"
                icon
                :color="`${checktags ? 'primary' : 'grey'}`"
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>Текущий список тэгов на странице</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class=""
                v-bind="attrs"
                v-on="on"
                @click="showSearch = !showSearch"
                icon
                color="primary"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Поиск по названию</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class=""
                v-bind="attrs"
                v-on="on"
                @click="sortTags = !sortTags"
                icon
                color="primary"
              >
                <v-icon v-if="sortTags">mdi-sort-variant</v-icon>
                <v-icon v-else>mdi-sort-reverse-variant</v-icon>
              </v-btn>
            </template>
            <span>Сортировать</span>
          </v-tooltip>
          <v-tooltip bottom v-if="tagSearchModel.length != 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="tagSearchModel.length == 0"
                class=""
                @click="cleanTagSearch"
                icon
                color="primary"
              >
                <v-icon small>mdi-backspace</v-icon>
              </v-btn>
            </template>
            <span>Сбросить выделенное</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-btn @click="menuTag = false" icon color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <transition name="fade">
          <v-text-field
            v-if="showSearch"
            autofocus
            placeholder="Найти..."
            filled
            rounded
            v-model="searchTag"
            dense
          ></v-text-field>
        </transition>
        <v-list-item-group
          class="tagSearchMenu"
          @change="checkTags"
          v-model="tagSearchModel"
          multiple
        >
          <v-subheader>Выбери #тэг из списка</v-subheader>
          <template v-for="(item, i) in filteredListSearch">
            <v-list-item
              :key="`item-${i}`"
              :value="item"
              active-class="deep-orange--text text--accent-4"
            >
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title class="d-flex justify-space-between">
                    <span class="tagContent">{{ i + 1 }}. #{{ item }}</span>
                    <span class="tagCounter_list">
                      <span
                        class="tagCounter_item"
                        v-for="(i, data, index) in counterState(item)"
                        :key="index"
                        :class="data"
                      >
                        {{ i }}
                      </span>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-show="false">
                  <v-checkbox
                    :input-value="active"
                    color="deep-orange accent-4"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-menu nudge-left="250%" bottom rounded="lg" :close-on-content-click="false">
      <template v-slot:activator="{ on: menusearch, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <div class="menuCleanFilter">
              <v-btn
                color="primary"
                dark
                class="ma-1"
                small
                fab
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menusearch }"
              >
                <v-icon > mdi-magnify </v-icon>
              </v-btn>
              <v-btn
                v-if="searchfield"
                class="btnCleanFilter"
                icon
                @click="clearInput"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </template>
          <span>Поиск</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item>
          <v-text-field
            v-model="searchfield"
            autofocus
            @input="searchDatas"
            label="Имя креатива"
          ></v-text-field>
          <v-btn icon @click="clearInput"> <v-icon>mdi-close</v-icon></v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu nudge-left="80" bottom rounded="lg">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <div class="menuCleanFilter">
              <v-btn
                color="primary"
                dark
                class="ma-1"
                small
                fab
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon > mdi-filter </v-icon>
              </v-btn>
              <v-btn
                v-if="$store.getters.GET_FILTER_TYPE != 'all'"
                class="btnCleanFilter"
                icon
                @click="clearFilterType"
              >
                <v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </template>
          <span>Фильтр</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          @click="filterBtn(item)"
          v-for="(item, index) in items"
          :key="index"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        { id: "all", title: "Все" },
        { id: "image", title: "Баннер" },
        { id: "gif", title: "GIF" },
        { id: "video", title: "Видео" },
      ],
      searchfield: "",
      tagSearch: "",
      showSearch: false,
      searchTag: "",
      menuTag: false,
      sortTags: false,
      checktags: true,
    };
  },
  computed: {
    searchDatas() {
      this.$store.commit("SEARCH_DATAS", this.searchfield);
    },
    tagSearchModel: {
      get() {
        return this.$store.getters.GET_TAG_SEARCH_MODEL;
      },
      set(value) {
        this.$store.commit("SET_SEARCH_TAG", value);
      },
    },
    filteredListSearch() {
      let arr = this.$store.getters.GET_TAGS_LIST.filter((el) => {
        return el.toLowerCase().includes(this.searchTag.toLowerCase());
      });
      if (this.sortTags) {
        arr = arr.sort();
      } else {
        arr = arr.reverse();
      }
      return arr;
    },
  },
  created() {
    this.checktags = JSON.parse(localStorage.getItem("tagBar")) || false;
    this.$store.commit("SET_TAGS_BAR", this.checktags);
  },
  watch: {
    checktags(val) {
      localStorage.setItem("tagBar", val);
      this.$store.commit("SET_TAGS_BAR", val);
    },
  },
  methods: {
    checkTags() {
      this.$store.commit("SET_CHECKLIST_TAGS");
    },
    filterBtn(item) {
      this.$store.commit("SET_FILTER_TYPE", item.id);
    },
    clearInput() {
      this.searchfield = "";
    },
    clearFilterType() {
      this.$store.commit("SET_FILTER_TYPE", "all");
    },
    cleanTagSearch() {
      this.tagSearchModel = [];
      this.checkTags();
    },
    counterState(item) {
      const newData = this.$store.getters.GET_MAIN_DATAS;
      const arr = {};
      for (let key in newData) {
        arr[key] = newData[key]
          .map((el) => {
            return el.tags.includes(item);
          })
          .filter((el) => el).length;
      }
      return arr;
    },
  },
};
</script>
<style lang="scss">
.tagContent {
  max-width: 65%;
  overflow: hidden;
}
.filterWrapper {
  // position: fixed;
  display: flex;
  justify-content: center;
  // flex-direction: column;
  // right: 10px;
  // bottom: 10px;
}
.menuCleanFilter {
  position: relative;
  .btnCleanFilter {
    position: absolute;
    right: -20px;
    top: -20px;
  }
}
.tagSearchMenu {
  width: 320px;
  height: 350px;
  overflow: auto;
  padding-right: 20px;
  margin-bottom: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.tagCounter_list {
  color: white;
  display: flex;
  justify-content: center;
  .tagCounter_item {
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 11px;
    line-height: 22px;
    border-radius: 50%;
    padding: 0 3px;
    margin: 0 2px;
  }
  .approved {
    background: #91c16b;
    order: 0;
  }
  .declined {
    background: #e74c3c;
  }
  .waiting {
    background: #f8cf15;
    order: -1;
  }
}
</style>
