<template>
  <div class="leftPanel">
    <div title="Всплыть вверх!" class="getupBtn" @click="goTop">
      <v-icon> mdi-chevron-up </v-icon>
    </div>
    <div class="changeTabs">
      <div
        @click="changeTab(index)"
        class="leftPanelStatus"
        :class="`${item.status}`"
        v-for="(item, index) in stateApp"
        :key="index"
      >
       <v-icon> mdi-{{item.icon}} </v-icon>
      </div>
    </div>
    <div title="На дно!" class="getupBtn" @click="goDown">
      <v-icon> mdi-chevron-down </v-icon>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      stateApp: [
        { status: "waiting", icon: "clock-outline" },
        { status: "approved", icon: "thumb-up" },
        { status: "declined", icon: "thumb-down" },
      ],
    }
  },
  methods: {
    goTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    goDown() {
      document.body.scrollTop = document.body.scrollHeight;
      document.documentElement.scrollTop = document.body.scrollHeight;
    },
    changeTab(index) {
      this.$emit("change-tab", index);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/leftpanel.scss";
</style>
