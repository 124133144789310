<template>
    <div class="confirmWraper dialogItemNameWrapper confirmInterface">
        <div>
            <div class="appItemBox">
                <div class="itemWrapperPage">
                    <div class="itemImageWrapper">
                        <ImageItem :item="dataItem" />
                    </div>
                </div>
                <div class="itemWrapperPage itemPanelWrapper">
                    <div class="itemNameWrapper">
                        <span>Имя: </span>
                        <strong>{{ item.name }}</strong>
                    </div>
                    <Tags :tags="item.tags" :id="item.id" :chipVer="true" />
                    <CommeAndDesc :activateAppItemPage="true" :item="dataItem" />
                </div>
            </div>
            <div class="changeStatusBox">
                <div class="changeStatus">
                    <ChangeStatus :item="dataItem" />
                </div>
            </div>
        </div>
        <div class="sliderBtnWrapper">
            <v-btn icon color="primary" @click="arrowCreative('prev')">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="arrowCreative('next')">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
import ImageItem from "./ImageItem";
import Tags from "./Tags";
import CommeAndDesc from "./CommeAndDesc";
import ChangeStatus from "./ChangeStatus";
export default {
    props: ["item"],
    components: {
        ImageItem,
        CommeAndDesc,
        ChangeStatus,
        Tags
    },
    data() {
        return {
            dataItem: {}
        };
    },
    watch: {
        item(val) {
            this.initialize();
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.dataItem = this.item;
        },
        arrowCreative(item) {
            this.$emit("action-arrow", item);
        }
    }
};
</script>
