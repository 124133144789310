var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"itemCreativeInfo"},[_c('div',{staticClass:"infoText filterItemField",class:{
      'aciveFilter aciveFilterImage':
        _vm.$store.getters.GET_FILTER_TYPE == 'image',
    },on:{"click":function($event){return _vm.filterActive('image')}}},[_c('span',{staticClass:"circle-info circle-image"}),_c('span',{staticClass:"filterImage"},[_vm._v("Баннеры ("+_vm._s(_vm.creativeTypeCounter("image") || 0)+")")])]),_c('div',{staticClass:"infoText filterItemField",class:{
      'aciveFilter aciveFilterGif': _vm.$store.getters.GET_FILTER_TYPE == 'gif',
    },on:{"click":function($event){return _vm.filterActive('gif')}}},[_c('span',{staticClass:"circle-info circle-gif"}),_c('span',{staticClass:"filterGif"},[_vm._v("GIF ("+_vm._s(_vm.creativeTypeCounter("gif") || 0)+")")])]),_c('div',{staticClass:"infoText filterItemField",class:{
      'aciveFilter aciveFilterVideo':
        _vm.$store.getters.GET_FILTER_TYPE == 'video',
    },on:{"click":function($event){return _vm.filterActive('video')}}},[_c('span',{staticClass:"circle-info circle-video"}),_c('span',{staticClass:"filterVideo"},[_vm._v("Видео ("+_vm._s(_vm.creativeTypeCounter("video") || 0)+")")])])])}
var staticRenderFns = []

export { render, staticRenderFns }