<template>
  <v-dialog class="confirmDialog" max-width="434px" v-model="isOpen">
    <div class="confirmWraper">
      <div class="dialogClose" @click="isOpen = false">
        <v-icon> mdi-close </v-icon>
      </div>
      <div class="confirmTitle">
        Чтобы отправить элемент в <span>Отклонено</span> добавьте комментарий
      </div>

      <div class="confirmBtnList" v-if="groupMess">
        <v-text-field
          label="Коммент"
          v-model="comment"
          autofocus
          placeholder="Например 'Исправить лого'"
        ></v-text-field>
        <v-btn icon color="primary" @click="send">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: ["openDialog", "groupMess"],
  data() {
    return {
      isOpen: false,
      comment: "",
    };
  },
  watch: {
    openDialog(val) {
      this.isOpen = val;
    },
    isOpen(val) {
      if (!val) {
        this.$emit("close-dialog");
      }
    },
  },
  methods: {
    send() {
      if (this.comment.length > 0) {
        this.$emit("comment-fordecline", this.comment);
        this.comment = "";
      }
      this.$emit("close-dialog");
    },
  },
};
</script>
