<template>
  <div class="itemWrapper">
    <v-lazy
      v-model="isActive"
      :options="{
        threshold: 0.5,
      }"
      min-height="200"
      transition="fade-transition"
    >
      <div
        :loading="loading"
        class="cardWrap"
        :class="[isChecking ? 'isActive' : '']"
      >
        <div
          @click="check"
          class="typeCreativeCircle"
          :class="typeIcon"
        >
          <v-icon  color="green" v-if="mineTypeCreative == 'image'"> mdi-image </v-icon>
          <v-icon  color="purple" v-if="mineTypeCreative == 'video'"> mdi-video </v-icon>
          <v-icon  color="light-blue lighten-2" v-if="mineTypeCreative == 'gif'"> mdi-file-gif-box </v-icon>
        </div>
        <div class="itemContainer">
          <div class="appItem_header">
            <button @click="check" class="checkButton">
              <v-icon :class="[isChecking ? 'activeCheck' : '']">
                mdi-check-circle
              </v-icon>
            </button>
          </div>
          <ImageItem
            :class="{ limiterHeight: $store.getters.GET_LIMIT_HEIGHT }"
            :item="item"
          />
          <Tags :tags="item.tags" :id="item.id" :chipVer="true" />
          <div class="changeStatus" v-if="showChangeStatusBar">
            <ChangeStatus :item="item" />
          </div>
        </div>
        <v-divider></v-divider>
        <div class="itemContainer">
          <CommeAndDesc :item="item" />
        </div>
      </div>
    </v-lazy>
  </div>
</template>
<script>
import ImageItem from "./ImageItem";
import CommeAndDesc from "./CommeAndDesc";
import ChangeStatus from "./ChangeStatus";
import Tags from "./Tags";
export default {
  props: ["item", "changeCheck", "showChangeStatusBar"],
  components: {
    ImageItem,
    CommeAndDesc,
    ChangeStatus,
    Tags,
  },
  data: () => ({
    loading: false,
    isActive: false,
    isChecking: false,
  }),
  created() {
    this.initialize();
  },
  watch: {
    changeCheck: function (newVal) {
      this.isChecking = newVal;
      this.checked();
    },
  },
  computed: {
    mineTypeCreative() {
      if (this.item.mimeType.split("/")[0] === "image") {
        if (this.item.mimeType.split("/")[1] === "gif") {

          return "gif";
        }
        return "image";
      } else return "video";
    },
    typeIcon(){
      if (this.mineTypeCreative == 'image'){
        return 'cardImageIcon'
      }
      if (this.mineTypeCreative == 'video'){
        return 'cardVideoIcon'
      }
      if (this.mineTypeCreative == 'gif'){
        return 'cardGifIcon'
      }
    }
  },
  methods: {
    initialize() {
      const localBool = JSON.parse(localStorage.getItem("limitHeight"));
      if ([true, false].includes(localBool)) {
        this.$store.commit("SET_LIMIT_HEIGHT", localBool);
      } else {
        localStorage.setItem("limitHeight", JSON.stringify(false));
      }
    },
    check() {
      this.isChecking = !this.isChecking;
      this.checked();
    },
    checked() {
      if (this.isChecking) {
        this.$emit("up-check", this.item.id);
      } else {
        this.$emit("down-check", this.item.id);
      }
    },
    
  },
};
</script>
<style lang="scss">
@import "../assets/css/appitems.scss";
</style>
