<template>
  <div class="heightLimiterWrap">
    <v-checkbox
      @change="isChecked"
      v-model="checkHeight"
      color="orange"
      hide-details
      :label="checkHeight ? 'Ограничено' : 'Полностью'"
    ></v-checkbox>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checkHeight: true,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.$store.getters.GET_LIMIT_HEIGHT) {
        this.checkHeight = true;
      } else {
        this.checkHeight = false;
      }
      this.isChecked();
    },
    isChecked() {
      localStorage.setItem("limitHeight", JSON.stringify(this.checkHeight));
      this.$store.commit("SET_LIMIT_HEIGHT", this.checkHeight);
    },
  },
};
</script>