<template>
  <div class="homePage">
    <div v-if="isAuth" class="isAuth">
      <div v-if="urlError" class="errorBox">
        Данные отсутствуют :(
        <p>Пожалуйста сообщите вашему менеджеру</p>
        <v-btn color="white" icon @click="reload"
          ><v-icon> mdi-cached </v-icon></v-btn
        >
      </div>
      <div v-else class="homeWrapper">
        <div class="goUpBtn">
          <GoogleDriveSync />
          <CreativeInterface />
          <LeftPanel @change-tab="changeTab" />
        </div>
        <v-tabs
          background-color="#f3f3f3"
          v-model="currentTab"
          class="tabBox"
          centered
          icons
        >
          <v-tab
            :class="`${item.status}`"
            v-for="(item, index) in stateApp"
            :key="index"
          >
            <v-icon> {{ item.icon }} </v-icon>
            <span class="tabName">
              <span class="tabNameHide"> {{ stateRus[index] }}</span>
              <span v-if="GET_DATAS[stateApp[index].status]">
                ({{ GET_DATAS[stateApp[index].status].length || 0 }})
              </span>
              <span v-else>(0)</span>
            </span>
          </v-tab>

          <v-tab-item v-for="(item, index) in stateApp" :key="item.status">
            <div v-if="loadingCreativies" class="loaderImg bggrey">
              <div class="loader"></div>
            </div>
            <v-card v-else flat class="mainCard">
              <div v-if="loading" class="loaderImg">
                <div class="loader"></div>
              </div>
              <v-card-text v-else>
                <div>
                  <FilterItems />
                  <AppList
                    :applist="filterItem(stateApp[index].status)"
                    :stateApp="newStateApp()"
                    :itemStatus="item.status"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <div v-else class="welcomPage">
      <WelcomeUserPage :itemData="itemData" />
    </div>
  </div>
</template>

<script>
import AppList from "@/components/AppList";
import WelcomeUserPage from "@/components/WelcomeUserPage";
import LeftPanel from "@/components/LeftPanel";
import CheckHeight from "@/components/CheckHeight";
import CreativeInterface from "@/components/CreativeInterface";
import FilterItems from "@/components/FilterItems";
import GoogleDriveSync from "@/components/GoogleDriveSync";
import { mapGetters } from "vuex";
export default {
  components: {
    AppList,
    WelcomeUserPage,
    LeftPanel,
    CheckHeight,
    CreativeInterface,
    FilterItems,
    GoogleDriveSync,
  },
  data() {
    return {
      loadingCreativies: false,
      currentTab: 1,
      itemData: {
        welcometext: "в Creative Approval System!",
        description: "Вам необходимо авторизоваться",
        unAuth: false,
        isAdmin: false,
      },
      urlError: false,
      loading: false,
      isAuth: true,
      stateRus: ["В ожидании", "Одобрено", "Отклонено"],
      stateApp: [
        { status: "waiting", icon: "watch_later" },
        { status: "approved", icon: "thumb_up" },
        { status: "declined", icon: "thumb_down" },
      ],
      itemlist: [],
    };
  },

  computed: {
    ...mapGetters(["GET_DATAS"]),
  },
  created() {
    this.initialize();
  },
  watch: {
    currentTab: function (newVal) {
      localStorage.setItem("currentTab", newVal);
    },
  },
  methods: {
    initialize() {
      this.createDatas();
      if (localStorage.getItem("currentTab")) {
        this.currentTab = +localStorage.getItem("currentTab");
      } else {
        localStorage.setItem("currentTab", 1);
        this.currentTab = +localStorage.getItem("currentTab");
      }
    },
    reload() {
      location.reload(true);
    },
    filterItem(status) {
      if (this.GET_DATAS) {
        return this.GET_DATAS[status];
      }
      return [];
    },

    newStateApp() {
      return this.stateApp.map((el) => el.status);
    },
    createDatas() {
      this.loadingCreativies = true;

      if (
        this.GET_DATAS.approved &&
        this.GET_DATAS.declined &&
        this.GET_DATAS.waiting
      ) {
        this.loadingCreativies = false;
      } else {
        this.$store.dispatch("GET_DATAS_FROM_API").then((el) => {
          if (el.status == false) {
            this.isAuth = false;
            const error = el.data.response;
            if (error.status == 400) {
              // this.itemData.unAuth = true;
            }
            if (error.status == 401) {
              this.itemData.unAuth = true;
            }
          } else if (el == true) {
            this.isAuth = true;
            this.loadingCreativies = false;
            this.$localforage.setItem(
              "main_data",
              this.$store.getters.GET_DATAS
            );
            this.changeEmptyTab(); //автоматически переключать на не пустой таб
          }
        });
      }
    },
    changeEmptyTab() {
      const currentTabLength = this.filterItem(
        this.stateApp[this.currentTab].status
      ).length;
      if (currentTabLength == 0) {
        if (this.currentTab == 2) {
          this.currentTab = 0;
        } else {
          this.currentTab++;
        }
        this.changeEmptyTab();
      }
    },
    changeTab(index) {
      this.currentTab = index;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/home.scss";
</style>
