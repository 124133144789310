<template>
  <v-main style="min-height: 300px">
    <DialogDecline :openDialog="confirmDialog" :groupMess="true" @comment-fordecline="commentForDecline"
      @close-dialog="confirmDialog = false" />
    <!-- panel -->
    <div class="flexCenter buttonList" v-if="abyss.length > 0">
      <div class="flexCenter buttonListUp">
        <div class="flexCenter checkInfo">
          <v-icon class="iconCheck"> mdi-check-circle </v-icon>
          <span class="infoText">
            Выбрано: <span>{{ abyss.length }}</span>
          </span>
        </div>
        <button v-if="!changeCheck" @click="changeCheck = true" class="checkBtnList cheackBtn">
          Выбрать все
        </button>
        <button v-else @click="changeCheck = false" class="checkBtnList cheackBtn">
          Снять все
        </button>
      </div>
      <div class="deliver"></div>
      <div class="flexCenter buttonListDown">
        <span class="moveTitle">Отправить в:</span>
        <div class="">
          <button v-for="(i, index) in $store.getters.GET_STATE" :key="index" class="checkBtnList btnChangeList"
            @click="changeStatus(i)" :class="[i == itemStatus ? 'disabled' : '', i + 'btn']">
            {{ stateRus[index] }}
          </button>
        </div>
      </div>
      <!-- <div class="flexCenter clearAll" @click="closePanel">
        <v-icon> mdi-close </v-icon>
      </div> -->
    </div>
    <div v-if="isLoading" class="loaderImg">
      <div class="loader"></div>
    </div>
    <div v-else class="itemCreativeWrapper">
      <div v-if="GET_INTERFACE_TYPE == 0">
        <FilterVideoPhoto :applist="applist" />
        <TagBar :status="itemStatus" :item="filteredList" />
        <div class="emptyList" v-if="filteredList.length == 0">
          <div class="whiteText">Пусто...</div>
        </div>
        <div class="appItemImageWrapper">
          <AppItemImage v-for="item in paginated" :item="item" :key="item.id" :showChangeStatusBar="showChangeStatusBar"
            :changeCheck="changeCheck" @up-check="checks" @down-check="notchecks" />
        </div>
        <div class="text-center">
          <v-pagination v-model="page" :length="Math.ceil(filteredList.length / 6)" circle :total-visible="7">
          </v-pagination>
        </div>

      </div>
      <div v-if="GET_INTERFACE_TYPE == 1" class="appItemNameWrapper">
        <FilterVideoPhoto :applist="applist" />
        <TagBar :status="itemStatus" :item="filteredList" />
        <div class="emptyList" v-if="filteredList.length == 0">
          <div class="whiteText">Пусто...</div>
        </div>
        <v-dialog v-model="dialogViewOpen" class="confirmDialog dialogItemName" max-width="1024px">
          <DialogItemName :item="dialogViewItem" @action-arrow="actionArrow" />
        </v-dialog>

        <AppItemName v-for="item in filteredList" :item="item" :key="item.id" :changeCheck="changeCheck"
          @up-check="checks" @down-check="notchecks" @dialog-view="dialogView" />
      </div>

      <div v-if="GET_INTERFACE_TYPE == 2" class="appItemSliderWrapper">
        <FilterVideoPhoto :applist="applist" />
        <TagBar :status="itemStatus" :item="filteredList" />
        <div class="emptyList" v-if="filteredList.length == 0">
          <div class="whiteText">Пусто...</div>
        </div>
        <AppItemFullList v-for="(item, index) in paginated" :key="index" :item="item" :changeCheck="changeCheck"
          @up-check="checks" @down-check="notchecks" />
        <div class="text-center">
          <v-pagination v-model="page" :length="Math.ceil(filteredList.length / 6)" circle :total-visible="7">
          </v-pagination>
        </div>
      </div>
      <div v-if="GET_INTERFACE_TYPE == 3" class="appItemSliderWrapper">
        <v-carousel v-model="model" light hide-delimiters height="100%">
          <v-carousel-item v-for="(item, index) in filteredList" :key="index" height="100%">
            <AppItemSlider :item="item" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <template>

      </template>
    </div>
    <v-dialog v-model="dialogError" class="confirmDialog" max-width="450px">
      <div class="confirmWraper confirmInterface">
        <div class="dialogClose" @click="dialogError = false">
          <v-icon> mdi-close </v-icon>
        </div>
        <div class="confirmTitle">Ошибка сервиса :(</div>
      </div>
    </v-dialog>
  </v-main>
</template>
<script>
import AppItemImage from "./AppItemImage";
import AppItemName from "./AppItemName";
import AppItemFullList from "./AppItemFullList";
import AppItemSlider from "./AppItemSlider";
import DialogDecline from "./DialogDecline";
import DialogItemName from "./DialogItemName";
import FilterVideoPhoto from "./FilterVideoPhoto";
import TagBar from "./TagBar";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  props: ["applist", "itemStatus"],
  components: {
    AppItemImage,
    AppItemName,
    AppItemSlider,
    AppItemFullList,
    DialogDecline,
    DialogItemName,
    FilterVideoPhoto,
    TagBar,
  },
  data: () => ({
    abyss: [],
    stateRus: ["В ожидании", "Одобрено", "Отклонено"],
    isLoading: false,
    panel: false,
    isSwitched: false,
    changeCheck: false,
    moveError: false,
    moveErrorSuccses: 0,
    moveList: 0,
    confirmDialog: false,
    model: 0,
    dialogError: false,
    dialogViewOpen: false,
    dialogViewItem: {},
    nocomment_creatives: [],
    showChangeStatusBar: true,
    page: 1
  }),
  created() {
    this.initialize();
  },
  watch: {
    abyss(num) {
      if (this.abyss.length == 0) {
        this.showChangeStatusBar = true;
      } else {
        this.showChangeStatusBar = false;
      }
    },
  },
  computed: {
    ...mapGetters(["GET_INTERFACE_TYPE", "GET_FILTER_TYPE"]),
    filteredList(id) {
      if (this.GET_FILTER_TYPE == "all") {
        return this.applist;
      } else {
        return this.applist.filter((el) => {
          if (this.GET_FILTER_TYPE == "gif") {
            return el.mimeType.split("/")[1] == "gif";
          }
          if (this.GET_FILTER_TYPE == "image") {
            if (
              el.mimeType.split("/")[0] == "image" &&
              el.mimeType.split("/")[1] != "gif"
            )
              return el.mimeType.split("/")[0];
          }
          if (this.GET_FILTER_TYPE == "video") {
            return el.mimeType.split("/")[0] == "video";
          }
        });
      }
    },
    paginated(content) {
      const currentPageElemBegin = (this.page - 1) * 6
      const currentPageElemEnd = (this.page * 6)
      return this.filteredList.slice(currentPageElemBegin, currentPageElemEnd)
    }
  },
  methods: {
    ...mapActions(["CHANGE_GROUPFILE_STATUS", "ADD_COMMENT"]),
    ...mapMutations([
      "STATUS_CREATIVE",
      "SET_INTERFACE",
      "SET_SEARCH_TAG",
      "SET_CHECKLIST_TAGS",
    ]),
    initialize() {
      if (localStorage.getItem("menuSwitch") in [0, 1, 2]) {
        this.SET_INTERFACE(localStorage.getItem("menuSwitch"));
      } else {
        this.SET_INTERFACE(0);
      }
      this.setLocalSearchTag();
    },
    changeStatus(moveTo) {
      let move_arr = 0;
      if (moveTo == "declined") {
        let arr_list = [];
        this.applist.map((el) => {
          this.abyss.map((elem) => {
            if (elem == el.id) {
              arr_list.push(el);
            }
          });
        });
        let move_arr_list = arr_list.filter((el) => {
          //выбранные креативы с комментариями
          return el.commentsLength != 0;
        });
        this.nocomment_creatives = arr_list.filter((el) => {
          //выбранные креативы без комментариями
          return el.commentsLength == 0;
        });
        this.abyss = move_arr_list.map((el) => {
          return el.id;
        });
        if (arr_list.length != move_arr_list.length) {
          move_arr = arr_list.length;
        }
      }
      if (this.nocomment_creatives.length > 0) {
        this.confirmDialog = true;
        console.log("confirmDialog", this.confirmDialog);
        console.log("dddddd2");
      }
      this.changingFolder(moveTo, this.abyss, move_arr);
    },
    changingFolder(moveTo, items, move_arr) {
      this.statusChangeArray(moveTo, items);
      this.changeCheck = false;
      this.panel = false;
      this.moveErrorSuccses = this.abyss.length;
      this.moveList = move_arr;
      this.abyss = [];
      if (move_arr > 0) {
        console.log("move_arr", move_arr);
        // this.isLoading = false;
        this.moveError = true;
        this.panel = false;
        this.changeCheck = false;
        this.abyss = [];
      }
      if (items.length > 0) {
        this.$store.commit("SET_GOOGLE_LOADER", true);
        this.CHANGE_GROUPFILE_STATUS({
          ids: items,
          to: moveTo,
        }).then((el) => {
          if (el.data == "success") {
            this.$store.commit("SET_GOOGLE_LOADER", false);
          } else {
            console.log("error");
            this.dialogError = true;
          }
        });
      }
    },
    statusChangeArray(moveTo, items) {
      for (let i = 0; i < items.length; i++) {
        const data = {
          id: items[i],
          movefrom: this.itemStatus,
          moveto: moveTo,
        };
        this.STATUS_CREATIVE(data);
      }
    },

    checks(id) {
      this.panel = true;
      if (!this.abyss.includes(id)) {
        this.abyss.push(id);
      }
    },
    notchecks(id) {
      this.abyss = this.abyss.filter((el) => el !== id);
    },
    closePanel() {
      this.changeCheck = false;
      this.panel = false;
      //this.abyss = []
    },
    dialogView(item) {
      this.dialogViewOpen = true;
      this.dialogViewItem = item;
    },
    actionArrow(item) {
      let index = this.filteredList
        .map((el) => {
          return el.id;
        })
        .indexOf(this.dialogViewItem.id);
      if (item == "prev") {
        if (index == 0) {
          index = this.filteredList.length;
        }
        this.dialogViewItem = this.filteredList[--index];
      }
      if (item == "next") {
        if (index == this.filteredList.length - 1) {
          index = -1;
        }
        this.dialogViewItem = this.filteredList[++index];
      }
    },
    commentForDecline(comment) {
      this.nocomment_creatives.forEach((element) => {
        this.ADD_COMMENT({
          file_id: element.id,
          comment_id: "string",
          content: comment,
        }).then((el) => {
          if (el.status) {
            this.confirmDialog = false;
          }
        });
      });
      const ids = this.nocomment_creatives.map((el) => {
        return el.id;
      });
      this.confirmDialog = false;
      this.changingFolder("declined", ids, this.nocomment_creatives.length);
      this.nocomment_creatives = [];
    },
    setLocalSearchTag() {
      if (!localStorage.getItem("filterTag")) {
        this.emptyTagListLocal();
      }
      const arr = localStorage.getItem("filterTag");
      if (arr[0] == "[" && arr[arr.length - 1] == "]") {
        let arrList = JSON.parse(localStorage.getItem("filterTag"));
        if (arrList != null && Array.isArray(arrList)) {
          arrList = this.arrOfUnique(arrList);
          this.SET_SEARCH_TAG(arrList);
          this.SET_CHECKLIST_TAGS();
        }
      } else {
        this.emptyTagListLocal();
      }
    },
    emptyTagListLocal() {
      let arr = [];
      localStorage.setItem("filterTag", JSON.stringify(arr));
    },
    arrOfUnique(list) {
      return list.filter((item, index) => {
        return list.indexOf(item) === index;
      });
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/applist.scss";
</style>
