<template>
  <div class="itemWrapper">
    <v-lazy
      v-model="isActive"
      :options="{
        threshold: 0.5,
      }"
      transition="fade-transition"
    >
      <div
        :loading="loading"
        class="cardWrapName"
        :class="[isChecking ? 'isActive' : '']"
      >
        <div class="itemContainer nameContainer">
          <div class="nameItemLeft">
            <button @click="check" class="checkButton checkNameButton">
              <v-icon :class="[isChecking ? 'activeCheck' : '']">
                mdi-check-circle
              </v-icon>
            </button>
     
            <div @click="check" class="typeCreativeCircle" :class="typeIcon">
              <v-icon color="green" v-if="mineTypeCreative == 'image'">
                mdi-image
              </v-icon>
              <v-icon color="purple" v-if="mineTypeCreative == 'video'">
                mdi-video
              </v-icon>
              <v-icon
                color="light-blue lighten-2"
                v-if="mineTypeCreative == 'gif'"
              >
                mdi-file-gif-box
              </v-icon>
            </div>
            <div class="name-typeWrapper">
              <span class="itemNameTitle">
                <span class="creativeNameHide creative_title">Имя: </span>
                <span class="">
                  {{ item.name }}
                </span>
              </span>
              <span class="itemTypeTitle">
                <span class="creativeNameHide creative_title">Тип: </span>
                <span class="">
                  {{ item.mimeType }}
                </span>
              </span>
              <Tags :tags="item.tags" :id="item.id" :chipVer="false" />
            </div>
          </div>
          <button class="btn btn2 btnRoute" @click="openSliderView">
            <span class="creativeNameHide"> Открыть </span>
            <v-icon> mdi-eye </v-icon>
          </button>
        </div>
      </div>
    </v-lazy>
  </div>
</template>
<script>
import Tags from "./Tags";
export default {
  props: ["item"],
  components: {
    Tags,
  },
  data: () => ({
    loading: false,
    isActive: false,
    isChecking: false,
    error: false,
  }),
  computed: {
    mineTypeCreative() {
      if (this.item.mimeType.split("/")[0] === "image") {
        if (this.item.mimeType.split("/")[1] === "gif") {
          return "gif";
        }
        return "image";
      } else return "video";
    },
    typeIcon() {
      if (this.mineTypeCreative == "image") {
        return "cardImageIcon";
      }
      if (this.mineTypeCreative == "video") {
        return "cardVideoIcon";
      }
      if (this.mineTypeCreative == "gif") {
        return "cardGifIcon";
      }
    },
  },
  methods: {
    openSliderView() {
      this.$store.commit("SET_ITEM", this.item);
      this.$emit("dialog-view", this.item);
    },
    check() {
      this.isChecking = !this.isChecking;
      this.checked();
    },
    checked() {
      if (this.isChecking) {
        this.$emit("up-check", this.item.id);
      } else {
        this.$emit("down-check", this.item.id);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/appitemname.scss";
</style>
