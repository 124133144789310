<template>
  <v-hover v-slot="{ hover }">
    <div class="googleSyncWrapper">
      <div
        class="addloader addloaderBlock"
        v-if="$store.getters.GET_GOOGLE_LOADER"
      ></div>
      <v-icon color="green darken-1" v-else-if="!hover"> mdi-google-drive </v-icon>
      <v-btn @click="reflesh" v-else color="orange darken-1" small icon><v-icon> mdi-cached </v-icon></v-btn>
    </div>
  </v-hover>
</template>
<script>
export default {
  methods: {
    reflesh(){
     this.$store.dispatch("GET_DATAS_FROM_API").then((el) => {
          if (el.status == false) {
            const error = el.data.response;
            console.log('error',el.data);
          } else if (el == true) {
             this.$localforage.setItem("main_data", this.$store.getters.GET_DATAS);
          }
        });
    }
  },
};
</script>
<style lang="scss">
.googleSyncWrapper {
  margin: 5px 0;
  width: 45px;
  height: 45px;
  position: relative;
  background-color: white;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
}
.addloaderBlock {
  position: relative;
}
</style>