<template>
  <div class="appItemPage">
    <div class="appItemWrapper">
      <div class="appItemPageContainer">
        <div class="backRouteWrapper">
          <button @click="check" class="checkButton checkNameButton">
            <v-icon :class="[isChecking ? 'activeCheck' : '']">
              mdi-check-circle
            </v-icon>
          </button>
        </div>
        <div class="appItemBox">
          <div class="itemWrapperPage">
            <div class="itemImageWrapper">
              <ImageItem :item="item" />
            </div>
          </div>
          <div class="itemWrapperPage itemPanelWrapper">
            <Tags :tags="item.tags" :id="item.id" :chipVer="true" />
            <div class="itemNameWrapper">
              <span>Имя: </span>
              <strong>{{ item.name }}</strong>
            </div>
            <CommeAndDesc :activateAppItemPage="true" :item="item" />
          </div>
        </div>
        <div class="changeStatusBox">
          <div class="changeStatus">
            <ChangeStatus :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageItem from "./ImageItem";
import CommeAndDesc from "./CommeAndDesc";
import ChangeStatus from "./ChangeStatus";
import Tags from "./Tags";
export default {
  props: ["item", "changeCheck"],
  components: {
    ImageItem,
    CommeAndDesc,
    ChangeStatus,
    Tags,
  },
  data() {
    return {
      error: false,
      isActive: false,
      isChecking: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.error = this.emptyObject(this.$store.getters.GET_ITEM);
    },
    emptyObject(obj) {
      for (let key in obj) {
        return false;
      }
      return true;
    },
    check() {
      this.isChecking = !this.isChecking;
      this.checked();
    },
    checked() {
      if (this.isChecking) {
        this.$emit("up-check", this.item.id);
      } else {
        this.$emit("down-check", this.item.id);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/appitempage.scss";
</style>
