<template>
    <div class="tagBar_list" v-if="tagBar">
        <span class="tagBar_item" @click="searchTag(tag)" v-for="(tag, index) in tagList" :key="index">
            <span :class="activeTag2(tag)">#{{ tag }}</span>
        </span>
        <v-btn @click="clearTagFilter" icon small v-if="this.$store.getters.GET_TAG_SEARCH_MODEL.length > 0"><v-icon small> mdi-close </v-icon></v-btn>
    </div>
</template>
<script>
export default {
    props: ["item", "status"],
    computed: {
        tagList() {
            let uniqueTag = [];
            const allTags = this.$store.getters.GET_MAIN_DATAS[this.status]
                .map(el => {
                    return el.tags;
                })
                .map(el => {
                    uniqueTag = uniqueTag.concat(el);
                });
            return uniqueTag.filter((item, index) => {
                return uniqueTag.indexOf(item) === index;
            });
        },
        tagBar(){
            return  this.$store.getters.GET_TAGS_BAR
        }
    },
    methods: {
        searchTag(tag) {
            if (!this.$store.getters.GET_TAG_SEARCH_MODEL.includes(tag)) {
                this.$store.commit("SET_SEARCH_TAG_PUSH", tag);
                this.$store.commit("SET_CHECKLIST_TAGS");
            } else {
                this.$store.commit("SET_SEARCH_TAG_DELETE", tag);
                this.$store.commit("SET_CHECKLIST_TAGS");
            }
        },
        activeTag2(tag) {
            if (this.$store.getters.GET_TAG_SEARCH_MODEL.includes(tag)) {
                return "blueTag";
            }
        },
        clearTagFilter(){
            this.$store.commit("SET_SEARCH_TAG",[]);
            this.$store.commit("SET_CHECKLIST_TAGS");
        }
    }
};
</script>
<style lang="scss">
.tagBar_list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 95%;
    margin: 10px auto 0;
    .tagBar_item {
        margin: 0 5px;
        cursor: pointer;
        color: #ff7323;
        transition: 0.4s;
        &:hover {
            opacity: 0.7;
        }
    }
}
</style>
