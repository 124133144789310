<template>
  <div>
    <button
      title="Настройка интерфейса"
      class="btnSettings leftPanelSetting"
      @click="dialog = true"
    >
      <v-icon> mdi-cog </v-icon>
    </button>
    <v-dialog v-model="dialog" class="confirmDialog" max-width="450px">
      <div class="confirmWraper confirmInterface">
        <div class="dialogClose" @click="dialog = false">
          <v-icon> mdi-close </v-icon>
        </div>
        <div class="confirmTitle">Найстройка интерфейса</div>
        <div class="">
          <v-tabs v-model="tab" show-arrows background-color="transparent">
            <v-tab v-for="item in items" :key="item.title">
              <v-icon> mdi-{{ item.icon }} </v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.id">
              <v-card flat>
                <v-card-text class="intefaceTitle">{{
                  item.title
                }}</v-card-text>
                <v-img
                  class="interfaceImage"
                  aspect-ratio="1.7"
                  :src="require(`@/assets/img/${item.img}.jpg`)"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <div class="mt-5">
            Ограничить высоту креативов:
            <CheckHeight />
            <v-img
              v-if="getHeight"
              class="interfaceImage"
              aspect-ratio="1.7"
              src="@/assets/img/with_limit_height.jpg"
            />
            <v-img
              v-else
              class="interfaceImage"
              aspect-ratio="1.7"
              src="@/assets/img/no_limot_height.jpg"
            />
          </div>
          <div class="mt-5">
            Отобразить список тэгов сверху:
            <v-checkbox
              v-model="checktags"
              :label="`${checktags ? 'Включен' : 'Выключен'}`"
            ></v-checkbox>
            <v-img
              v-if="checktags"
              class="interfaceImage"
              aspect-ratio="4.5"
              src="@/assets/img/tagson.jpg"
            />
            <v-img
              v-else
              class="interfaceImage"
              aspect-ratio="5.5"
              src="@/assets/img/tagsoff.jpg"
            />
          </div>
          <div class="confirmBtnList">
            <button class="btn mt-3" @click="switchMenu">
              Выбрать
            </button>
            <button class="btn btn2 mt-3" @click="dialog = false">
              Отмена
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import CheckHeight from "./CheckHeight";
export default {
  components: {
    CheckHeight,
  },
  data() {
    return {
      checktags: true,
      dialog: false,
      tab: 0,
      items: [
        {
          id: 0,
          title: "Плитки",
          img: "creative_flat",
          icon: "view-grid-outline",
        },
        {
          id: 1,
          title: "Список",
          img: "creative_list",
          icon: "view-headline",
        },
        {
          id: 2,
          title: "Полный список",
          img: "creative_fulllist",
          icon: "card-account-details",
        },
        {
          id: 3,
          title: "Слайдер",
          img: "creative_slider",
          icon: "view-carousel",
        },
      ],
    };
  },
  computed: {
    getHeight() {
      return this.$store.getters.GET_LIMIT_HEIGHT;
    },
  },
  methods: {
    image(item) {
      return `../assets/img/${item.img}.jpg`;
    },
    switchMenu() {
      localStorage.setItem("menuSwitch", this.tab);
      this.$store.commit("SET_INTERFACE", this.tab);
      this.dialog = false;
    },
  },
  created() {
   this.checktags = JSON.parse(localStorage.getItem('tagBar')) || false
   this.$store.commit("SET_TAGS_BAR", this.checktags);
  },
  watch: {
    checktags(val){
      localStorage.setItem("tagBar", val);
      this.$store.commit("SET_TAGS_BAR", val);
    }
  },
};
</script>
<style lang="scss">
@import "../assets/css/creative_inteface.scss";
</style>
