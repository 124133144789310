<template>
  <div class="itemCreativeInfo">
    <div
      class="infoText filterItemField"
      :class="{
        'aciveFilter aciveFilterImage':
          $store.getters.GET_FILTER_TYPE == 'image',
      }"
      @click="filterActive('image')"
    >
      <span class="circle-info circle-image"></span>
      <span class="filterImage"
        >Баннеры ({{ creativeTypeCounter("image") || 0 }})</span
      >
    </div>
    <div
      class="infoText filterItemField"
      :class="{
        'aciveFilter aciveFilterGif': $store.getters.GET_FILTER_TYPE == 'gif',
      }"
      @click="filterActive('gif')"
    >
      <span class="circle-info circle-gif"></span>
      <span class="filterGif">GIF ({{ creativeTypeCounter("gif") || 0 }})</span>
    </div>
    <div
      class="infoText filterItemField"
      :class="{
        'aciveFilter aciveFilterVideo':
          $store.getters.GET_FILTER_TYPE == 'video',
      }"
      @click="filterActive('video')"
    >
      <span class="circle-info circle-video"></span>
      <span class="filterVideo"
        >Видео ({{ creativeTypeCounter("video") || 0 }})</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["applist"],
  methods: {
    creativeTypeCounter(type) {
      const arr = this.applist.filter((el) => {
        // if (type == "gif") {
        //   return el.mimeType.split("/")[1] == type;
        // } else return el.mimeType.split("/")[0] == type;
        if (type == "gif") {
          return el.mimeType.split("/")[1] == "gif";
        }
        if (type == "image") {
          if (
            el.mimeType.split("/")[0] == "image" &&
            el.mimeType.split("/")[1] != "gif"
          )
            return el.mimeType.split("/")[0];
        }
        if (type == "video") {
          return el.mimeType.split("/")[0] == "video";
        }
        
      });
      return arr.length;
    },
    filterActive(type) {
      if (this.$store.getters.GET_FILTER_TYPE == type) {
        this.$store.commit("SET_FILTER_TYPE", "all");
        return;
      }
      this.$store.commit("SET_FILTER_TYPE", type);
      // this.$store.commit("SET_FILTER_TYPE", "all");
    },
  },
};
</script>