<template>
  <div class="">
    <div class="tagWrapper " v-if="chipVer">
       <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
         <v-btn icon v-bind="attrs"
          v-on="on" color="light-green darken-4" @click="openTagDialog"> # </v-btn>
      </template>
      <span>Добавить #тэг</span>
    </v-tooltip>
     
      <div class="tagListWrapper">
        <v-chip
        v-for="(tag, index) in tagsLocal"
        :key="index"
        class="ma-1 pa-1"
        @click="searchTag(tag)"
        :color="activeTag(tag)"
        outlined
      >
        #{{ tag }}
      </v-chip>
      </div>
      
    </div>
    <span class="itemTypeTitle  d-flex align-center flex-wrap " v-else>
      <v-btn icon small color="primary" @click="openTagDialog"> # </v-btn>
      <span
        class="tagLinkName"
        v-for="(tag, index) in tagsLocal"
        :key="index"
        @click="searchTag(tag)"
      >
        <span :class="activeTag2(tag)">#{{ tag }}</span>
      </span>
    </span>
    <v-dialog class="confirmDialog" max-width="434px" v-model="tagDialog">
      <div class="confirmWraper">
        <div class="dialogClose" @click="tagDialog = false">
          <v-icon> mdi-close </v-icon>
        </div>
        <div class="confirmTitle">Список <span>Тэгов</span> креатива</div>
        <div class="counter_tag">
          <span class="counter_tag_red" v-if="counterTag > 8"> ! Лимит ! </span>
          <span>{{ counterTag || 0 }}</span
          >/8
        </div>
        <div class="tag_list">
          <!-- <div class="addloader" v-if="isLoading"></div> -->
          <div
            class="tag_item"
            v-for="(tag, index) in localTagList"
            :key="index"
          >
            #{{ tag }}
            <v-btn icon small @click="deleteTag(index)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="tag_list">
          <div class="tag_item" v-for="(tag, index) in selected" :key="index">
            <span><span class="plus">+</span>{{ tag }}</span>
             <div class="addloader" v-if="isLoading"></div>
             <v-btn v-else icon small @click="deleteAddTag(index)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-autocomplete
          v-model="selected"
          :items="tagListFilter"
          label="Выберите тэг"
          hide-details
          chips
          multiple
          hide-selected
          single-line
          clearable
          :menu-props="{ closeOnContentClick: closeOnContentClickBoolka }"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Добавить тэг?</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn icon color="primary" @click="openCreateField">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-form
          ref="form"
          v-model="valid"
          class="confirmBtnList"
          v-if="openCreateNew"
        >
          <v-text-field
            label="Новый тэг"
            v-model="comment"
            autofocus
            counter
            maxlength="20"
            :rules="[rules.required, rules.email, rules.counter]"
            placeholder="Например: товар"
          ></v-text-field>
          <v-btn :disabled="!valid" icon color="primary" @click="addTag">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-form>
        <div class="tagBtn_group">
          <v-btn icon color="primary" @click="openCreateNew = !openCreateNew">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="primary"
            @click="tagHelpField = !tagHelpField"
          >
            <v-icon small>mdi-help</v-icon>
          </v-btn>
        </div>
        <div class="tag_help my-2" v-if="tagHelpField">
          Инструкция: если тэг не появился в списке нужно подождать 1-2 минуты
          :D
        </div>
        <div class="" v-if="counterTag <= 8">
          <button class="btn" @click="saveTag">Сохранить</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["tags", "id", "chipVer"],
  data: () => ({
    valid: false,
    tagDialog: false,
    tagHelpField: false,
    comment: "",
    isLoading: false,
    selected: [],
    item: "",
    localTagList: [],
    openCreateNew: false,
    closeOnContentClickBoolka: false,
    rules: {
      required: (value) => !!value || "Пусто!",
      counter: (value) => value.length <= 20 || "Максимум 20 символов",
      email: (value) => {
        const pattern = /^[a-zA-Zа-яА-ЯёЁ0-9\s]+$/;
        return pattern.test(value) || "Только буквы и цифры!";
      },
    },
    newAddTag: [],
    limiterTag: false,
  }),
  computed: {
    tagListFilter() {
      const taglist = this.$store.getters.GET_TAGS_LIST.filter((el) => {
        return !this.tagsLocal.includes(el);
      });
      return taglist;
    },
    tagsLocal: function () {
      return this.tags.concat(this.newAddTag);
    },
    counterTag() {
      return this.tagsLocal.length + this.selected.length;
    },
  },
  created() {
    this.localTagList = this.tags;
  },
  watch: {
    counterTag(value) {
      if (value > 8) {
        this.limiterTag = false;
      } else {
        this.limiterTag = true;
      }
    },
  },
  methods: {
    openTagDialog() {
      this.tagDialog = true;
    },
    saveTag() {
      const tagList = this.tagsLocal.concat(this.selected);
      const dataUpdate = {
        id: this.id,
        tags: tagList,
      };
      this.isLoading = true;
      this.$store.dispatch("TAGS_UPDATE", dataUpdate).then((el) => {
        if (el.status == true) {
          this.isLoading = false;
          this.localTagList = tagList;
        //   this.newAddTag = this.selected;
          this.openCreateNew = false;
          this.selected = [];
          this.$store.commit("SET_NEW_TAGLIST_ITEM", dataUpdate);
        }
      });
    },
    addTag() {
      this.selected.push(this.comment);
      this.$store.commit("ADD_NEW_TAG", this.comment);
      this.comment = "";
      this.$refs.form.resetValidation();
    },
    openCreateField() {
      this.openCreateNew = true;
      this.closeOnContentClickBoolka = true;
    },
    deleteTag(i) {
      console.log(i);
      this.localTagList.splice(i, 1);
    },
    deleteAddTag(i) {
      this.selected.splice(i, 1);
    },
    searchTag(tag) {
      if (!this.$store.getters.GET_TAG_SEARCH_MODEL.includes(tag)) {
        this.$store.commit("SET_SEARCH_TAG_PUSH", tag);
        this.$store.commit("SET_CHECKLIST_TAGS");
      } else {
        this.$store.commit("SET_SEARCH_TAG_DELETE", tag);
        this.$store.commit("SET_CHECKLIST_TAGS");
      }
    },
    activeTag(tag) {
      if (this.$store.getters.GET_TAG_SEARCH_MODEL.includes(tag)) {
        return "blue";
      } else {
        return "green";
      }
    },
    activeTag2(tag) {
      if (this.$store.getters.GET_TAG_SEARCH_MODEL.includes(tag)) {
        return "blueTag";
      }
    },
  },
};
</script>
<style lang="scss">
.tag_list {
  position: relative;
}
.tag_item {
  cursor: pointer;
  transition: 0.4s;
  color: rgb(124, 122, 122);
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: rgb(255, 94, 0);
  }
  .plus {
    color: rgb(255, 94, 0);
    font-weight: bold;
    margin-right: 3px;
  }
}
.tagBtn_group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.counter_tag {
  text-align: right;
  color: rgb(255, 94, 0);
  margin: 3px 0 10px;
  font-size: 12px;
  .counter_tag_red {
    color: red;
  }
}
.tagLinkName {
  cursor: pointer;
  color: #b7b7b7;
  margin: 0 4px;
  transition: 0.4s;
  &:hover {
    color: #ff7323;
  }
}
.blueTag {
  color: #2196f3;
}
.tagWrapper{
  min-height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;
  background-color: rgb(253, 252, 252);
  margin: 10px 0;
  border-radius: 20px;
  padding: 5px ;
}
.tagListWrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
